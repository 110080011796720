<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1 class="pb-5">Client ratecards for: {{ client.name }}</h1>
      </div>
      <div class="cell small-12 medium-2">
        <button @click="rateCardAdd = true" class="button expanded">Add Rate Card</button>
      </div>
    </div>
    <div v-for="(ratecard, rcindex) in client.rate_cards" :key="`rc_${rcindex}`">
      <table>
        <thead>
          <tr>
            <th colspan="2">Ratecard #{{ rcindex + 1 }} {{ ratecard.description }}</th>
            <th  class="text-right">
              <span
                  title="Add rate"
                  class="material-symbols-outlined icon-green icon-link"
                  @click="addRate = rcindex"
                  >add</span>
              <span
                @click="rateCardInEdit = rcindex, selectedRateCard = ratecard"
                title="Edit rate card"
                class="material-symbols-outlined icon-yellow icon-link"
                >edit</span>
              <span
                  title="Delete rate card"
                  class="material-symbols-outlined icon-red icon-link"
                  @click="confirmDeleteCard = rcindex"
                  >delete</span>
            </th>
          </tr>
          <tr>
            <th>Job Title</th>
            <th>Rate</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rate, index) in ratecard.rates" :key="`r_${index}`">
            <td>{{ rate.jobTitle }}</td>
            <td>{{ rate.hourlyRate | currency }}</td>
            <td class="text-right">
              <span
                  class="material-symbols-outlined icon-yellow icon-link"
                  @click="rcInEdit = rcindex, rateInEdit = index"
                  >edit</span>
                &nbsp;
                <span
                  class="material-symbols-outlined icon-red icon-link"
                  @click="rcDelete = rcindex, rdelete = index"
                  >delete</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="rateCardAdd === true" class="dialogue">
      <label>New ratecard description</label>
      <input type="text" v-model="newRateCard.description" />
      <label>Brand</label>
      <select v-model="newRateCard.brand_id">
        <option value="0">All</option>
        <option
          v-for="(brand, index) in client.brands"
          :value="brand.id"
          :key="`br_${index}`">
          {{ brand.name }}
        </option>
      </select>
      <button
        @click="addRateCard"
        class="button small">Save</button>
      <button
        @click="rateCardAdd = false, newRateCard = {}"
        class="button small float-right mt-1">Cancel</button>
    </div>
    <div v-if="rateCardInEdit > -1" class="dialogue">
      <label>Ratecard #{{ (rateCardInEdit + 1) }} description</label>
      <input type="text" v-model="selectedRateCard.description" />
      <label>Brand</label>
      <select v-model="selectedRateCard.brand_id">
        <option value="0">All</option>
        <option
          v-for="(brand, index) in client.brands"
          :value="brand.id"
          :key="`br_${index}`">
          {{ brand.name }}
        </option>
      </select>
      <button
        @click="saveRatecard"
        class="button small">Save</button>
      <button
        @click="rateCardInEdit = -1, selectedRateCard = {}"
        class="button small float-right mt-1">Cancel</button>
    </div>
    <div v-if="addRate > -1" class="dialogue">
      <label>Job Title</label>
      <select v-model="newRate.job_title_id">
        <option
          v-for="(title, index) in jobTitles"
          :value="title.id"
          :key="`ti_${index}`">
          {{ title.title }}
        </option>
      </select>
      <label>Hourly rate</label>
      <input type="text" v-model="newRate.hourly_rate" />
      <button
        @click="saveNewRate"
        class="button small">Save</button>
      <button
        @click="addRate = -1, newRate = {}"
        class="button small float-right">Cancel</button>
    </div>
    <div v-if="rateInEdit > -1" class="dialogue">
      <label>{{ selectedRate.job_title.title }} hourly rate</label>
      <input type="text" v-model="selectedRate.hourly_rate" />
      <button
        @click="saveUpdate"
        class="button small">Save</button>
      <button
        @click="rateInEdit = -1, rcInEdit = -1, selectedRate = {}"
        class="button small float-right">Cancel</button>
    </div>
    <div v-if="confirmDeleteCard > -1" class="dialogue">
      <p>Confirm you wish to delete the entire rate card</p>
      <button
        @click="deleteRateCard"
        class="button small">Delete</button>
      <button
        @click="confirmDeleteCard = -1"
        class="button small float-right">Cancel</button>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ClientsRatecard',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      addRate: -1,
      client: {
        rate_cards: [
          {
            rates: [],
          },
        ],
      },
      confirmDeleteCard: -1,
      jobTitles: [],
      newRate: {
        job_title_id: 0,
        hourly_rate: 0,
      },
      newRateCard: {
        descripton: '',
      },
      rateCardAdd: false,
      rateCardInEdit: -1,
      rateInEdit: -1,
      rcDelete: -1,
      rcInEdit: -1,
      rdelete: -1,
      selectedRate: {},
      selectedRateCard: {},
    };
  },
  watch: {
    rdelete() {
      this.deleteRate();
    },
    rateInEdit() {
      if (this.rcInEdit !== -1) {
        this.selectedRate = this.client.rate_cards[this.rcInEdit].rates[this.rateInEdit];
      }
    },
  },
  methods: {
    saveNewRate() {
      const postData = {};
      postData.ratecard = this.client.rate_cards[this.addRate].id;
      postData.newRate = this.newRate;
      axios.post(`/rates/create.json?token=${this.token}`, postData)
        .then((response) => {
          const updatedRate = {};
          updatedRate.id = response.data.rate.id;
          updatedRate.hourlyRate = response.data.rate.hourlyRate;
          updatedRate.jobTitle = response.data.rate.jobTitle;
          updatedRate.job_title = response.data.rate.job_title;
          this.client.rate_cards[this.addRate].rates.push(updatedRate);
          // eslint-disable-next-line
          const rates = this.client.rate_cards[this.addRate].rates;
          rates.sort((a, b) => {
            if (a.jobTitle < b.jobTitle) {
              return -1;
            }
            if (a.jobTitle > b.jobTitle) {
              return 1;
            }
            return 0;
          });
          this.client.rate_cards[this.addRate].rates = rates;
          this.addRate = -1;
          this.newRate = {};
        })
        .catch(() => {
          this.failed = true;
        });
    },
    addRateCard() {
      const postData = {};
      postData.ratecard = this.newRateCard;
      postData.clientId = this.client.id;
      axios.post(`/rateCards/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.client.rate_cards.push(response.data.ratecard);
          this.newRateCard = {};
          this.rateCardAdd = false;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    saveRatecard() {
      const postData = {};
      postData.ratecard = this.selectedRateCard;
      axios.post(`/rateCards/update.json?token=${this.token}`, postData)
        .then(() => {
          this.selectedRateCard = {};
          this.rateCardInEdit = -1;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    deleteRateCard() {
      const postData = {};
      postData.ratecard = this.client.rate_cards[this.confirmDeleteCard];
      axios.post(`/rateCards/delete.json?token=${this.token}`, postData)
        .then(() => {
          this.client.rate_cards.splice(this.confirmDeleteCard, 1);
          this.confirmDeleteCard = -1;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    deleteRate() {
      const postData = {};
      postData.rate = this.client.rate_cards[this.rcDelete].rates[this.rdelete];
      axios.post(`/rates/delete.json?token=${this.token}`, postData)
        .then(() => {
          this.client.rate_cards[this.rcDelete].rates.splice(this.rdelete, 1);
          this.rcDelete = -1;
          this.rdelete = 1;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    saveUpdate() {
      const postData = {};
      postData.rate = this.selectedRate;
      axios.post(`/rates/update.json?token=${this.token}`, postData)
        .then((response) => {
          const updatedRate = {};
          updatedRate.id = response.data.rate.id;
          updatedRate.hourlyRate = response.data.rate.hourlyRate;
          updatedRate.jobTitle = response.data.rate.jobTitle;
          updatedRate.job_title = response.data.rate.job_title;
          this.client.rate_cards[this.rcInEdit].rates[this.rateInEdit] = updatedRate;
          this.rcInEdit = -1;
          this.rateInEdit = -1;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getClient() {
      const clientId = this.$route.params.id;
      axios.get(`/clients/getRatecard/${clientId}.json?token=${this.token}`)
        .then((response) => {
          this.client = response.data.client;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getJobTitles() {
      axios.get(`/jobTitles/fetchAll.json?token=${this.token}`)
        .then((response) => {
          this.jobTitles = response.data.jobTitles;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getClient();
      this.getJobTitles();
    }, 100);
  },
};
</script>
